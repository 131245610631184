import React from 'react'

import { Tags } from '../../components/Tags'
import { Discover } from '../../components/Discover'
import { WhyNamecheap } from '../../components/WhyNamecheap'
import { type Tool, MoreTools } from '../../components/MoreTools'
import { Header } from '../../components/Header'

import type { Tag } from '../../components/Tags'

import styles from './styles.module.scss'

type Props = {
  children: React.ReactNode
  tags: Tag[]
  moreTools?: Tool[]
  subtitleClassName?: string
  titleClassName?: string
}

export const CopyAndPaste: React.FC<Props> = ({ children, tags, moreTools, subtitleClassName, titleClassName }) => {
  return (
    <>
      <Header subtitleClassName={subtitleClassName} titleClassName={titleClassName} />
      <div className={styles.container}>
        {children}
        {moreTools ? <MoreTools tools={moreTools} /> : null}
        <Tags options={tags} />
        <Discover />
        <WhyNamecheap />
      </div>
    </>
  )
}
