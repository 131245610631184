export const variants = [
  { name: 'Check mark - tick', text: '✓' },
  { name: 'Ballot x - cross mark', text: '✗' },
  { name: 'Heavy check mark - tick', text: '✔' },
  { name: 'Empty ballot box', text: '☐' },
  { name: 'Ballot box with check', text: '☑' },
  { name: 'Ballot box with cross', text: '☒' },
  { name: 'Heavy ballot x - cross mark', text: '✘' },
  { name: 'Ballot box with ballot', text: '🗳' },
  { name: 'Green / white check mark', text: '✅' },
  { name: '100 / full marks', text: '💯' },
  { name: 'White flower', text: '💮' },
  { name: 'O / circle mark', text: '⭕' }
]

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Symbols' },
  { name: 'Emoji' },
  { name: 'Copy and paste' },
  { name: 'To-do lists' },
  { name: 'Dingbat' }
]

export const table1 = [
  ['Correct choice', '☐'],
  ['Popular choice', '☑']
]

export const table2 = [
  ['', 'Alt-code', 'HTML entity'],
  ['✓', '2713', '&#10003;'],
  ['✔', '2714', '&#10004;'],
  ['✗', '2717', '&#10007;'],
  ['✘', '2718', '&#10008;'],
  ['☐', '2610', '&#9744;'],
  ['☑', '2611', '&#9745;'],
  ['☒', '2612', '&#9746;'],
  ['⭕', '2B55', '&#11093;'],
  ['💯', '1F4AF', '&#128175;'],
  ['💮', '1F4AE', '&#128174;'],
  ['✅', '2705', '&#9989;'],
  ['🗳', '1F5F3', '&#128499;']
]
