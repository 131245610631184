import React from 'react'

import { Card } from '../../../components/Card'
import { Table } from '../../../components/Table'
import { TextGroup } from '../../../components/TextGroup'
import { TextWithImage } from '../../../components/TextWithImage'
import { VariantsContainer } from '../../../components/VariantsContainer'

import { CopyAndPaste } from '..'

import { table1, table2, tags, variants } from './constants'

import CheckmarkImg from './assets/checkmarks.svg'

import styles from './styles.module.scss'

const Checkmarks: React.FC = () => {
  return (
    <CopyAndPaste tags={tags} subtitleClassName={styles.subtitle} titleClassName={styles.title}>
      <VariantsContainer>
        {variants.map(variant => (<Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />))}
      </VariantsContainer>
      <TextWithImage
        title='What is a check mark?'
        image={<CheckmarkImg />}
        style={{ marginTop: 64 }}
      >
        <>
          <p>Check marks or ticks are used to show something is correct, chosen, complete, or verified.</p>
          <p>The tick ✔ comes from the letter V in the Latin word “veritas,” which means truth.</p>
          <p>Besides the traditional tick ✔, other variations on the check mark include the cross ✘, the slash /, and the check box ☑.</p>
        </>
      </TextWithImage>
      <TextGroup title='When to use a check mark' style={{ marginTop: 48 }}>
        <TextGroup className={styles['nested-text-group']} subtitle='Exams and quizzes'>
          <p>
            Exams and quizzes are often marked with ticks and crosses.
            In many places, a tick ✓ indicates a correct answer while a cross ✘ indicates an incorrect answer.
          </p>
          <p>
            However, using a tick ✓ to mean correct is not universal.
            Japan and Korea use circles ⭕ for correct answers and a slash /, cross ✘, or tick ✓ for an incorrect answer.
            Finland and Sweden both use tick marks ✓ for incorrect answers.
          </p>
          <p>
            The Netherlands, Indonesia, South Africa, and Suriname use the flourish of approval for correct answers. (Sadly, there is no Unicode symbol for this.)
          </p>
        </TextGroup>
      </TextGroup>
      <TextGroup subtitle='To-do lists' style={{ marginTop: 32 }}>
        To-do lists often use a check mark ✓ to indicate that an item is complete.
        Crosses ✘ and strikethroughs are also used. If you’d like to use strikethrough text, checkout the strikethrough generator.
      </TextGroup>
      <Card
        link={'/strikethrough/'}
        textWrap={true}
        className={styles['strikethrough-card']}
        style={{ marginTop: 32 }}
        title='Strikethrough generator'
        subtitle='7 variations'
        text='Create 𝚂̶𝚝̶𝚛̶𝚒̶𝚔̶𝚎̶𝚝̶𝚑̶𝚛̶𝚘̶𝚞̶𝚐̶𝚑̶, 𝚜̷𝚕̷𝚊̷𝚜̷𝚑̷𝚎̷𝚍̷, or ████████ text that works anywhere online, including social media posts and bios, emails, and lots more.'
        hideIcon
      />
      <TextGroup subtitle='Ballots' style={{ marginTop: 32 }}>
        Ballot papers use a check box — a box with a cross or tick — to denote a chosen candidate, party, or measure.
        An empty box is left for unchosen items.
      </TextGroup>
      <Table
        columnsWidth={[200, 200]}
        style={{ marginTop: 12 }}
      >
        {table1}
      </Table>
      <TextGroup title='What do 💯 and 💮 mean?' style={{ marginTop: 64 }}>
        You might be wondering why these emojis appear on this page. Both are used to mark school work in Japan, the birthplace of emojis.
      </TextGroup>
      <TextGroup subtitle='💯 One hundred points symbol' style={{ marginTop: 32 }}>
        This symbol simply means you got full marks, or 100%.
      </TextGroup>
      <TextGroup subtitle='💮 Hanamaru' style={{ marginTop: 32 }}>
        Hanamaru is a stamp or drawing a teacher would use on a particularly good piece of work.
        Sometimes, this emoji is rendered with the phrase “you did very well” and sometimes as the drawn version — a scribbled spiral with petals drawn around it.
      </TextGroup>
      <Table
        title='Check mark alt-codes'
        style={{ marginTop: 32 }}
        firstRowIsHeaders
      >
        {table2}
      </Table>
    </CopyAndPaste>
  )
}

export default Checkmarks
