import React, { type PropsWithChildren } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Props = {
  className?: string
  style?: React.CSSProperties
} & PropsWithChildren

export const VariantsContainer: React.FC<Props> = ({ children, className, style }) => {
  return (
    <div className={cn(styles.container, className)} style={style}>
      {children}
    </div>
  )
}
